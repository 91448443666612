export { default as compose } from "lodash/flowRight";

import seedrandom from "seedrandom";
import intersection from "lodash/intersection";
import { MixpanelEvent } from "@modules/home/components/MixpanelEvent";

export function getLastStringParamFromUrl(index = 1, param = "id") {
    let id = "";
    if (typeof window !== "undefined") {
        if (window.location.search) {
            id = new URL(location.href).searchParams.get(param) || "";
        } else {
            const urlByParts = window.location.pathname.split("/");
            id = urlByParts[urlByParts.length - index];
        }
    }
    return id;
}

export function randomIntFromInterval(seed, min, max) {
    // min and max included
    return Math.floor(seedrandom(seed)() * (max - min + 1) + min);
}
const globalParameter = [
    "controller",
    "utm_source",
    "questionText",
    "skipSound",
    "noCache",
    "referrer_screen",
];
export function getUrlAfterMaintainingParams(url, query, params = {}) {
    let newUrl = url.split("?")[0];
    const newQuery = JSON.parse(JSON.stringify(query));
    if (params?.referrer_screen) {
        delete newQuery.referrer_screen;
    }
    const intersectionParams = intersection(
        Object.keys(newQuery),
        globalParameter,
    );
    const hasInterSection = intersectionParams.length > 0;
    if (hasInterSection) {
        intersectionParams.map((param, i) => {
            newUrl += `${i === 0 ? "?" : "&"}${param}=${newQuery[param]}`;
        });
    }
    if (Object.keys(params).length > 0) {
        Object.keys(params).map((param, i) => {
            newUrl += `${i === 0 && !hasInterSection ? "?" : "&"}${param}=${
                params[param]
            }`;
        });
    }

    return newUrl;
}

export const PAGE_CONTEXT_NAME = {
    student_home_screen: "student_home_screen",
    worksheet_landing_screen: "worksheet_landing_screen",
    question_landing_screen: "question_landing_screen",
    gameplay_screen: "gameplay_screen",
    trophy_screen: "trophy_screen",
    pre_requisite_worksheet_story_screen:
        "pre_requisite_worksheet_story_screen",
    pre_requisite_worksheets_screen: "pre_requisite_worksheets_screen",
    before_payment_story_screen: "before_payment_story_screen",
    payment_screen: "payment_screen",
    razorpay_payment_screen: "razorpay_payment_screen",
    payment_success_story_screen: "payment_success_story_screen",
    payment_failed_story_screen: "payment_failed_story_screen",
    payment_cancel_story_screen: "payment_cancel_story_screen",
    onboarding_screen: "onboarding_screen",
    profile_screen: "profile_screen",
    direct: "direct",
};

export const referrer_screen_params = [
    "worksheet_id",
    "utm_source",
    "activity_id",
    "typ",
    "ref",
    "ch",
];

function getReferrerQueryValues(query) {
    const referrerQueryValues: any = {};
    referrer_screen_params.forEach((param) => {
        if (query[param]) {
            referrerQueryValues[param] = query[param];
        }
    });
    return Object.keys(referrerQueryValues)
        .map((key) => `${key}:${referrerQueryValues[key]}`)
        .join("__");
}

export const getPageContextName = (router, extraParams = {}) => {
    const { asPath, isReady, pathname, query: routerQuery } = router;
    const { extraPathname, extraQuery } = extraParams;
    let query = extraQuery ?? routerQuery;
    let user_is_currently_on = PAGE_CONTEXT_NAME.direct;

    switch (extraPathname || pathname) {
        case "/":
        case "/home":
        case "/home/[worksheet_id]":
            user_is_currently_on = PAGE_CONTEXT_NAME.student_home_screen;
            break;
        case "/home/worksheet/[worksheet_id]":
            const { isPreRequisiteStory } = extraParams;
            if (isPreRequisiteStory) {
                user_is_currently_on =
                    PAGE_CONTEXT_NAME.pre_requisite_worksheet_story_screen;
                break;
            }
            user_is_currently_on = PAGE_CONTEXT_NAME.worksheet_landing_screen;
            break;
        case "/home/worksheet/[worksheet_id]/[activity_id]":
            const { showQuestion } = query;
            const { isTrophy, isPayment, paymentScreenType } = extraParams;
            if (isPayment) {
                switch (paymentScreenType) {
                    case "before_payment":
                        user_is_currently_on =
                            PAGE_CONTEXT_NAME.before_payment_story_screen;
                        break;
                    case "payment":
                        user_is_currently_on = PAGE_CONTEXT_NAME.payment_screen;
                        break;
                    case "razorpay_payment":
                        user_is_currently_on =
                            PAGE_CONTEXT_NAME.razorpay_payment_screen;
                        break;
                    case "payment_success_story":
                        user_is_currently_on =
                            PAGE_CONTEXT_NAME.payment_success_story_screen;
                        break;
                    case "payment_failed_story":
                        user_is_currently_on =
                            PAGE_CONTEXT_NAME.payment_failed_story_screen;
                        break;
                    case "payment_cancel_story":
                        user_is_currently_on =
                            PAGE_CONTEXT_NAME.payment_cancel_story_screen;
                        break;
                }
                break;
            } else if (!showQuestion) {
                user_is_currently_on =
                    PAGE_CONTEXT_NAME.question_landing_screen;
                break;
            } else {
                if (isTrophy) {
                    user_is_currently_on = PAGE_CONTEXT_NAME.trophy_screen;
                    break;
                }

                user_is_currently_on = PAGE_CONTEXT_NAME.gameplay_screen;
            }
            break;
        case "/onboarding":
            user_is_currently_on = PAGE_CONTEXT_NAME.onboarding_screen;
            break;
        case "/profile":
            user_is_currently_on = PAGE_CONTEXT_NAME.profile_screen;
            break;
        case "/home/worksheet/[worksheet_id]/prerequisites":
            user_is_currently_on =
                PAGE_CONTEXT_NAME.pre_requisite_worksheets_screen;
            break;
        default:
            user_is_currently_on = PAGE_CONTEXT_NAME.direct;
    }

    MixpanelEvent.referrer_screen_info = getReferrerQueryValues(query);
    MixpanelEvent.user_is_currently_on = user_is_currently_on;

    return {
        user_is_currently_on,
    };
};

export function convertUrlToPathname(urlReceived) {
    if (!urlReceived) {
        return "/home";
    }

    let url: string = urlReceived.split("?")[0];
    if (!url.startsWith("/")) {
        url = `/${url}`;
    }

    // console.log("url", url);

    const dynamicRoutes = [
        { pattern: /^\/home$/, format: "/home" },
        {
            pattern: /^\/home\/worksheet\/\d+$/,
            format: "/home/worksheet/[worksheet_id]",
        },
        {
            pattern: /^\/home\/worksheet\/\d+\/\d+$/,
            format: "/home/worksheet/[worksheet_id]/[activity_id]",
        },
        {
            pattern: /^\/home\/worksheet\/\d+\/prerequisites$/,
            format: "/home/worksheet/[worksheet_id]/prerequisites",
        },
    ];

    for (const route of dynamicRoutes) {
        if (route.pattern.test(url)) {
            return route.format;
        }
    }

    return "/home"; // Return original URL if no match
}
